import NavBar from "../components/navBar";
import { useParams } from "react-router-dom";
import Button from "../common/button";
import QuantityCounter from "../common/quantityCounter";
import RadioButton from "../common/radioButton";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "../context/userContext";
import httpServices from "../auth/httpServices";

function SingleProductPage() {
  const userContext = useContext(UserContext);
  let params = useParams();
  const [count, setCount] = useState(1);
  const [productData, setProductData] = useState({
    _id: "",
    name: "",
    ingrediants: [],
    description: "",
    image: "",
    price: 0,
    currency: "",
    sizes: [],
    selectedSize: "",
    category: "",
  });
  const [isSelected, setIsSelected] = useState(true);

  const { _id, image, name, price, description, sizes, selectedSize } =
    productData;

  useEffect(() => {
    httpServices
      .get(`http://localhost:5000/product/${params.productId}`)
      .then((results) => {
        setProductData(results.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [params.productId]);

  function handleClickAddToCart(e) {
    const cartItems = localStorage.getItem("cartItems");
    const convertedItems = JSON.parse(cartItems) || [];
    console.log(convertedItems);
    const currentItem = convertedItems.find(
      (item) => item._id === _id && selectedSize === item.selectedSize
    );
    console.log(userContext);
    if (!selectedSize && !userContext._id) {
      setIsSelected(false);
    } else {
      if (!convertedItems.length) {
        localStorage.setItem(
          "cartItems",
          JSON.stringify([{ ...productData, quantity: count }])
        );
      } else {
        if (currentItem) {
          const filteredData = convertedItems.filter(
            (item) => item._id === _id
          );
          const filteredSizes = filteredData.filter(
            (item) => item.selectedSize !== selectedSize
          );
          const restItems = convertedItems.filter(
            (item) => item._id !== currentItem._id
          );

          localStorage.setItem(
            "cartItems",
            JSON.stringify([
              ...restItems,
              ...filteredSizes,
              { ...currentItem, quantity: count + currentItem.quantity },
            ])
          );
        } else {
          localStorage.setItem(
            "cartItems",
            JSON.stringify([
              ...convertedItems,
              { ...productData, quantity: count },
            ])
          );
        }
      }
    }
  }

  function handleSizeChange(e) {
    const { value } = e.target;
    setProductData({ ...productData, selectedSize: value });
    setIsSelected(true);
  }

  function handleIncrement(e, quantity) {
    setCount(quantity);
  }

  function handleDecrement(e, quantity) {
    setCount(quantity);
  }

  return (
    <div>
      <NavBar />
      <div className="product-head">
        <img src={image} alt="for product" />
        <h1>{name}</h1>
      </div>
      <div className="product-body">
        <div className="product-image-container">
          <img className="product-image" src={image} alt="for product body" />
        </div>
        <div className="product-info-container">
          <p className="product-name">{name}</p>
          <p className="product-price"> Rs.{price}</p>
          <hr />
          <p className="product-description">{description}</p>
          <p className="product-size">Size:</p>
          <div className="options-container">
            <form onChange={handleSizeChange}>
              {sizes.map((size) => (
                <RadioButton
                  key={size}
                  name="size"
                  id={size}
                  value={size}
                  label={size}
                />
              ))}
            </form>
          </div>
          <div className="counter-container">
            <p className="product-quantity">Quantity:</p>
            <QuantityCounter
              className="cart-quantity-counter"
              onIncrement={handleIncrement}
              onDecrement={handleDecrement}
            />
          </div>
          <Button className="click-add-to-cart" onClick={handleClickAddToCart}>
            Add to cart
          </Button>
          {!isSelected ? (
            <p className="warning-message">Please select a size !</p>
          ) : null}
          {!useContext && <p className="warning-message">Please sign in!</p>}
        </div>
      </div>
    </div>
  );
}

export default SingleProductPage;
