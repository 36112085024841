import NavBar from "../components/navBar";
import Category from "../components/category";
import { useEffect, useState } from "react";
import httpServices from "../auth/httpServices";

function Menu() {
  const [categories, setCategories] = useState([
    { id: "", name: "", image: "", items: [] },
  ]);

  useEffect(() => {
    httpServices
      .get("http://localhost:5000/categories")
      .then((results) => {
        setCategories(results.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <NavBar />
      <div className="menu-container">
        <h1>Menu</h1>
        <h2>Drinks</h2>
        <hr />
        <div className="categories-container">
          {categories.map((category) => (
            <Category
              key={category.id}
              link={category.id}
              name={category.name}
              image={category.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Menu;
