import "./App.css";
import { UserProvider } from "./context/userContext";
import MainRouter from "./router/mainRoutes";

function App() {
  return (
    <div>
      <UserProvider>
        <MainRouter />
      </UserProvider>
    </div>
  );
}

export default App;
