import React from "react";

function FacebookIcon(props) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    width={props.size || "30"}
      height={props.size || "30"}
      className={props.className}
    enableBackground="new 0 0 512 512"
    version="1.1"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <path d="M469.779 0H110.63a8.017 8.017 0 00-8.017 8.017 8.017 8.017 0 008.017 8.017h359.148c14.441 0 26.188 11.748 26.188 26.188v427.557c0 14.44-11.747 26.188-26.188 26.188H323.875V392.818a8.017 8.017 0 00-8.017-8.017 8.017 8.017 0 00-8.017 8.017v103.148h-52.376v-188.66a8.017 8.017 0 00-8.017-8.017H212.71v-52.376h34.739a8.017 8.017 0 008.017-8.017v-51.307c0-52.161 42.436-94.597 94.597-94.597h60.393v52.376h-60.393c-23.28 0-42.221 18.941-42.221 42.221v51.307a8.017 8.017 0 008.017 8.017h94.597v52.376h-94.597a8.017 8.017 0 00-8.017 8.017v51.307a8.017 8.017 0 008.017 8.017 8.017 8.017 0 008.017-8.017v-43.29h94.597a8.017 8.017 0 008.017-8.017v-68.409a8.017 8.017 0 00-8.017-8.017h-94.597v-43.29c0-14.44 11.747-26.188 26.188-26.188h68.409a8.017 8.017 0 008.017-8.017V84.977a8.017 8.017 0 00-8.017-8.017h-68.409c-61.002 0-110.63 49.629-110.63 110.63v43.29h-34.739a8.017 8.017 0 00-8.017 8.017v68.409a8.017 8.017 0 008.017 8.017h34.739v180.643H42.221c-14.441 0-26.188-11.748-26.188-26.188V42.221c0-14.44 11.747-26.188 26.188-26.188h34.205a8.017 8.017 0 100-16.033H42.221C18.941 0 0 18.941 0 42.221v427.557C0 493.059 18.941 512 42.221 512h427.557C493.059 512 512 493.059 512 469.779V42.221C512 18.941 493.059 0 469.779 0z"></path>
  </svg>
  );
}

export default FacebookIcon;
