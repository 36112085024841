import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { getCurrentUser } from "../auth/auth";
import { UserContext } from "../context/userContext";
import CartIcon from "../icons/cartIcon";

function NavBar() {
  const userContext = useContext(UserContext);
  const cartItems = localStorage.getItem("cartItems");
  const items = JSON.parse(cartItems);

  return (
    <div className="bar">
      <div className="logo-container">
        <a href="/" className="logo-text">
          Doha Coffee
        </a>
      </div>
      <Link className="menu-text" to="/menu">
        Menu
      </Link>
      <Link className="cart-container" to="/cart">
        <p className="cart-name">CART</p>
        <CartIcon />
        <div className="cart-badge">
          <p>{!items ? 0 : items.length}</p>
        </div>
      </Link>
      {userContext ? (
        <div className="profile-container">
          <p>{userContext.firstName}</p>
          <div className="profile-image-wrapper">
            <img
              src="images/me-croped.png"
              className="profile-image"
              alt="profile-pic"
            />
          </div>
        </div>
      ) : (
        <div className="links-container">
          <Link to="/signIn" className="signin-link">
            <p className="signin-name">SIGN IN |</p>
          </Link>
          <Link to="/signUp" className="signup-link">
            <p className="signup-name">SIGN UP</p>
          </Link>
        </div>
      )}
    </div>
  );
}

export default NavBar;
