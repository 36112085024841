import React from "react";

function InstagramIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width={props.size || "30"}
      height={props.size || "30"}
      className={props.className}
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path d="M359.312 0H152.689C68.849 0 .64 68.208.64 152.049v207.903C.64 443.792 68.849 512 152.689 512h206.623c83.839 0 152.048-68.208 152.048-152.048V152.049C511.359 68.208 443.151 0 359.312 0zm131.649 359.951c0 72.593-59.057 131.65-131.649 131.65H152.689c-72.593 0-131.65-59.057-131.65-131.649V152.049c0-72.593 59.057-131.65 131.65-131.65h206.623c72.592 0 131.649 59.057 131.649 131.65v207.902z"></path>
      <path d="M359.312 58.294H152.689c-51.698 0-93.756 42.057-93.756 93.755v207.904c0 51.697 42.058 93.755 93.756 93.755h206.623c51.697 0 93.755-42.058 93.755-93.755V152.049c0-51.697-42.058-93.755-93.755-93.755zm73.357 301.657c0 40.449-32.908 73.357-73.357 73.357H152.689c-40.45 0-73.358-32.908-73.358-73.357V152.048c0-40.449 32.908-73.357 73.358-73.357v.001h206.623c40.449 0 73.357 32.908 73.357 73.357v207.902z"></path>
      <path d="M256 124.178c-72.686 0-131.821 59.135-131.821 131.822S183.313 387.821 256 387.821 387.821 328.687 387.821 256 328.686 124.178 256 124.178zm0 243.244c-61.438 0-111.422-49.984-111.422-111.422 0-61.439 49.984-111.423 111.422-111.423 61.439 0 111.422 49.984 111.422 111.423 0 61.438-49.984 111.422-111.422 111.422z"></path>
      <path d="M256 181.041c-41.332 0-74.959 33.627-74.959 74.959s33.627 74.959 74.959 74.959c41.333 0 74.959-33.627 74.959-74.959S297.332 181.041 256 181.041zm0 129.52c-30.085 0-54.561-24.476-54.561-54.561s24.476-54.561 54.561-54.561 54.561 24.475 54.561 54.561c0 30.085-24.476 54.561-54.561 54.561zM380.485 99.843c-17.464 0-31.673 14.208-31.673 31.673s14.208 31.672 31.673 31.672c17.463 0 31.672-14.207 31.672-31.672s-14.209-31.673-31.672-31.673zm0 42.946c-6.216 0-11.274-5.057-11.274-11.273 0-6.217 5.058-11.274 11.274-11.274s11.273 5.058 11.273 11.274c.001 6.216-5.056 11.273-11.273 11.273z"></path>
      <g>
        <path d="M239.681 463.044h-95.872c-5.633 0-10.199 4.566-10.199 10.199s4.566 10.199 10.199 10.199h95.872c5.633 0 10.199-4.566 10.199-10.199s-4.566-10.199-10.199-10.199z"></path>
      </g>
      <g>
        <path d="M278.438 463.044h-6.12c-5.632 0-10.199 4.566-10.199 10.199s4.567 10.199 10.199 10.199h6.12c5.633 0 10.199-4.566 10.199-10.199s-4.567-10.199-10.199-10.199z"></path>
      </g>
    </svg>
  );
}

export default InstagramIcon;
