import { createContext, useEffect, useState } from "react";
import { getCurrentUser } from "../auth/auth";
import httpServices from "../auth/httpServices";

const UserContext = createContext();

function UserProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const user = getCurrentUser();
    if (user) {
      httpServices
        .get(`http://localhost:5000/users/${user.id}`)
        .then((results) => {
          setCurrentUser(results.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <UserContext.Provider value={currentUser}>{children}</UserContext.Provider>
  );
}

export { UserContext, UserProvider };
