import Category from "../components/category";
import NavBar from "../components/navBar";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import httpServices from "../auth/httpServices";

function ProductsPage() {
  let params = useParams();
  const [category, setCategory] = useState({
    id: "",
    name: "",
    image: "",
    items: [],
  });

  useEffect(() => {
    httpServices
      .get(`http://localhost:5000/categories/${params.category}`)
      .then((results) => {
        setCategory(results.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <NavBar />
      <div className="drinks-container">
        <h2>{category.name}</h2>
        <hr />
        <div className="drinks-map">
          {category.items.map((drink) => (
            <Category
              key={drink._id}
              link={String(drink._id)}
              data={drink}
              name={drink.name}
              image={drink.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProductsPage;
