import Button from "../../src/common/button";
import TextField from "../common/textField";
import NavBar from "../components/navBar";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { login } from "../auth/auth";
import httpServices from "../auth/httpServices";

function SignIn() {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const [inputErrors, setInputErrors] = useState({
    email: { isValid: true, message: " email is empty" },
    password: { isValid: true, message: " password is empty" },
  });

  function handleClick(e) {
    if (user.email && user.password) {
      httpServices
        .post("http://localhost:5000/user/login", user)
        .then((results) => {
          login(results.data.token);
          setUser(results.data);
          window.location.replace("/");
        })
        .catch((error) => {
          setErrorMessage(error.response.data.errors);
        });
    }
    setInputErrors({
      email: {
        ...inputErrors.email,
        isValid: !user.email ? false : true,
      },
      password: {
        ...inputErrors.password,
        isValid: !user.password ? false : true,
      },
    });
  }

  function handleInputFields(e) {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    setErrorMessage("");
    setInputErrors({
      ...inputErrors,
      [name]: { ...inputErrors[name], isValid: true },
    });
  }

  return (
    <div>
      <NavBar />
      <div className="auth-container">
        <div className="auth-content">
          <div className="auth-heading">
            <h1>Sign in!</h1>
          </div>
          <form className="auth-form">
            <TextField
              name="email"
              type="email"
              placeholder="Email"
              className="auth-input"
              onChange={handleInputFields}
              errorMessage={inputErrors.email.message}
              isValid={inputErrors.email.isValid}
            />
            <TextField
              name="password"
              type="password"
              placeholder="Password"
              className="auth-input"
              onChange={handleInputFields}
              errorMessage={inputErrors.password.message}
              isValid={inputErrors.password.isValid}
            />
            {setErrorMessage && (
              <p className="textfield-error">{errorMessage}</p>
            )}
          </form>
          <Button className="home-button" onClick={handleClick}>
            Sign In
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
