import axios from "axios";
import { getJwt } from "./auth";

axios.defaults.baseURL = "https://doha-coffee-backend.herokuapp.com";
axios.defaults.headers.common["Authorization"] = `Bearer ${getJwt()}`;
// axios.interceptors.response.use(null, (error) => {
//   const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;
//   if (!expectedError) {
//     console.log(error);
//     alert("An unexpected error occurred");
//   } else {
//     return Promise.reject(error);
//   }
// });

// export function getErr(error) {
//   if (error.response) {
//     if (error.response.status === 401) {
//       logout();
//       window.location = "/login";
//       return alert("invalid username or password or the Login is expired, please login again");
//     } else if (error.response.status === 401) {
//       alert(error.response.data.message);
//     }
//   }
// }

export default {
  get: axios.get,
  post: axios.post,
  patch: axios.patch,
  delete: axios.delete,
};
