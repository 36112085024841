import Button from "../../src/common/button";
import TextField from "../common/textField";
import NavBar from "../components/navBar";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import httpServices from "../auth/httpServices";

function SignUp() {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState("");

  const [inputErrors, setInputErrors] = useState({
    firstName: { isValid: true, message: "First name is empty" },
    lastName: { isValid: true, message: "Last name is empty" },
    email: { isValid: true, message: " email is empty" },
    password: { isValid: true, message: " password is empty" },
    confirmPassword: { isValid: true, message: "confirm password is empty" },
  });

  function handleClick(e) {
    if (user.firstName && user.lastName && user.email && user.password) {
      httpServices
        .post("http://localhost:5000/user/register", user)
        .then((results) => {
          console.log(results.data);
          setUser(results.data);
          navigate("/");
        })
        .catch((error) => {
          setErrorMessage(error.response.data.errors);
        });
    }

    setInputErrors({
      firstName: {
        ...inputErrors.firstName,
        isValid: !user.firstName ? false : true,
      },
      lastName: {
        ...inputErrors.lastName,
        isValid: !user.lastName ? false : true,
      },
      email: {
        ...inputErrors.email,
        isValid: !user.email ? false : true,
      },
      password: {
        ...inputErrors.password,
        isValid: !user.password ? false : true,
      },
      confiemPassword: {
        ...inputErrors.confirmPassword,
        isValid: !user.confirmPassword ? false : true,
      },
    });
  }

  function handleInputFields(e) {
    const { value, name } = e.target;
    setUser({ ...user, [name]: value });
    setInputErrors({
      ...inputErrors,
      [name]: {
        ...inputErrors[name],
        isValid: true,
      },
    });
  }

  return (
    <div>
      <NavBar />
      <div className="auth-container">
        <div className="auth-content">
          <div className="auth-heading">
            <h1>Create Account!</h1>
          </div>
          <form className="auth-form">
            <TextField
              name="firstName"
              type="text"
              placeholder="First Name"
              className="auth-input"
              onChange={handleInputFields}
              errorMessage={inputErrors.firstName.message}
              isValid={inputErrors.firstName.isValid}
            />
            <TextField
              name="lastName"
              type="text"
              placeholder="Last Name"
              className="auth-input"
              onChange={handleInputFields}
              errorMessage={inputErrors.lastName.message}
              isValid={inputErrors.lastName.isValid}
            />
            <TextField
              name="email"
              type="email"
              placeholder="Email"
              className="auth-input"
              onChange={handleInputFields}
              errorMessage={inputErrors.email.message}
              isValid={inputErrors.email.isValid}
            />
            <TextField
              name="password"
              type="password"
              placeholder="Password"
              className="auth-input"
              onChange={handleInputFields}
              errorMessage={inputErrors.password.message}
              isValid={inputErrors.password.isValid}
            />
            {/* <TextField
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              className="auth-input"
              errorMessage={inputErrors.confirmPassword.message}
              isValid={inputErrors.confirmPassword.isValid}
            /> */}
            {setErrorMessage && (
              <p className="textfield-error">{errorMessage}</p>
            )}
          </form>
          <Button className="home-button" onClick={handleClick}>
            Sign Up
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
